import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import VideoPage from './pages/VideoPage';

const { REACT_APP_AI_KEY } = process.env;
let reactPlugin = null;

if (REACT_APP_AI_KEY) {
  reactPlugin = new ReactPlugin();

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: REACT_APP_AI_KEY,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin]
    }
  });
  appInsights.loadAppInsights();
}

const App = () => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<VideoPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </AppInsightsContext.Provider>
  );
};

export default App;
