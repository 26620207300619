/* eslint-disable */
import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './VideoContainer.scss';

const PROGRESS_INTERVAL = 0.1;
const CTRL_KEYCODE = 17;

class VideoContainer extends Component {
  constructor(props) {
    super(props);
    this.videoPlayerRef = React.createRef();
    this.state = {
      playVideo: false,
      step: 0,
      isWaitingForFocus: false,
      targetHidden: props.targetHidden
    };
  }

  componentDidMount() {
    window.addEventListener('visibilitychange', this.onVisibilityChange);
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
    this.play();
  }

  componentDidUpdate() {
    if (this.props.triggerPlayVideo) {
      this.nextStep();
      this.props.setTriggerPlayVideo(false);
    }
    if (this.props.movedToPreviousStep) {
      this.previousStep();
      this.props.setMovedToPreviousStep(false);
    }
    if (this.props.seekTimeStamp !== null && this.props.videoStepIndex !== null) {
      this.seekTimeStamp();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
  }

  onVisibilityChange = () => {
    if (document.visibilityState === 'visible' && this.state.isWaitingForFocus) {
      this.setState({ isWaitingForFocus: false }, this.play);
    }
  };

  getCurrentProgress = ({ playedSeconds }) => {
    const { points } = this.props;
    if (!points || this.state.step >= points.length) {
      return;
    }

    const match = points[this.state.step];

    // Do not stop if next step is last
    if (playedSeconds > match.ts) {
      this.pause();
    }

    if (this.props.setSecondsPlayed) {
      this.props.setSecondsPlayed(playedSeconds);
    }
  };

  pause = () => {
    this.setState({
      playVideo: false
    });
    if (this.props.setIsVideoPlaying) {
      this.props.setIsVideoPlaying(false);
    }
  };

  play = () => {
    this.setState({
      playVideo: true
    });
    if (this.props.setIsVideoPlaying) {
      this.props.setIsVideoPlaying(true);
    }
  };

  hideTarget = () => {
    this.setState({
      targetHidden: true
    });
  };

  showTarget = () => {
    this.setState({
      targetHidden: false
    });
  };

  seekTimeStamp = () => {
    const { seekTimeStamp, onTimeStampChange, videoStepIndex, points } = this.props;
    if (this.videoPlayerRef && seekTimeStamp !== null && onTimeStampChange && videoStepIndex !== null) {
      // Update video time
      this.videoPlayerRef.current.seekTo(seekTimeStamp, 'seconds');

      onTimeStampChange();

      // Update current demo-step
      const match = points[videoStepIndex];

      if (match) {
        this.setState(
          () => ({
            step: videoStepIndex,
            isWaitingForFocus: typeof match.url !== 'undefined'
          }),
          () => {
            if (typeof match.url === 'undefined') {
              this.play();
            }
          }
        );
      }
    }
  };

  delay = (ms) => new Promise((res) => setTimeout(res, ms));

  onLastStep = () => {
    // Reset state step
    this.setState(() => ({
      step: 0
    }));

    // Seek second 0
    this.videoPlayerRef.current.seekTo(0);

    // Reset video index
    if (this.props.setVideoStepIndex) {
      this.props.setVideoStepIndex(0);
    }

    () => {
      this.play();
    };
  };

  previousStep = () => {
    if (this.state.step - 1 >= 0) {
      const { points, videoStepIndex } = this.props;

      this.setState((prevState) => ({
        step: prevState.step - 1
      }));
      if (this.props.setVideoStepIndex) {
        this.props.setVideoStepIndex(videoStepIndex - 1);
      }

      const match = points[this.state.step - 1];

      if (match) {
        // Seek to previous step timestamp
        this.videoPlayerRef.current.seekTo(match.ts);
      }

      this.play();
    }
  };

  nextStep = () => {
    const { step } = this.state;
    const { points } = this.props;
    const match = points[step];

    const updateState = () => {
      if (!points[step + 1]) {
        this.onLastStep();
      } else {
        this.setState(
          (prevState) => ({
            step: prevState.step + 1,
            isWaitingForFocus: typeof match.url !== 'undefined'
          }),
          () => {
            if (typeof match.url === 'undefined') {
              this.play();
            }
          }
        );
        if (this.props.setVideoStepIndex) {
          this.props.setVideoStepIndex(this.state.step + 1);
        }
      }
    };

    // Dynamically show/hide target from data file
    if (points[step + 1] && points[step + 1].targetHidden) {
      this.hideTarget();
    } else {
      this.showTarget();
    }

    if (match) {
      if (match.callback) {
        match.callback();
      }
      if (match.url) {
        this.props.openUrl(match.url);
      }
      if (match.audio) {
        var audio = new Audio(match.audio);
        audio.addEventListener('loadedmetadata', async (e) => {
          this.hideTarget();
          audio.play();
          await this.delay(e.target.duration * 1000);
          // Once audio is finished playing update state
          updateState();
          this.showTarget();
        });
        return;
      }
      updateState();
    }
  };

  onKeyDown = (e) => {
    e.preventDefault();

    this.setState({ guide: e.shiftKey || e.keyCode === CTRL_KEYCODE });

    const { playVideo, step } = this.state;
    const { points } = this.props;
    const match = points && step < points.length ? points[step] : null;
    // If user presses spacebar/enter, resume video
    const validKeys = [13, 32]; // 13 for Enter, 32 for Space
    if (!playVideo && match && (match.x === 0 || !!match.x) && validKeys.includes(e.keyCode)) {
      this.nextStep();
    }
  };

  onKeyUp = () => {
    this.setState({ guide: false });
  };

  getMatchStyle = (match) => {
    if (match) {
      return {
        left: `${match.x}%`,
        top: `${match.y}%`,
        width: `${match.w}%`,
        height: `${match.h}%`,
        cursor: match.skipCursor ? 'auto' : 'pointer',
        border: this.state.guide ? '2px solid red' : 'none',
        boxSizing: 'border-box'
      };
    }
    return null;
  };

  clickPointGuidedStyle = () => {
    const { step } = this.state;
    const { points, targetClickRef } = this.props;
    const match = points[step];

    let rect = null;

    if (targetClickRef.current) {
      rect = targetClickRef.current.getBoundingClientRect();
    }

    switch (match.clickPointType) {
      case 'square':
        return {
          left: `${match.x}%`,
          top: `${match.y}%`,
          width: `${match.w}%`,
          height: `${match.h}%`,
          animation: `1s ease 0.5s 1 pulse-green`,
          animationIterationCount: 'infinite'
        };
      case 'hidden':
        return {
          left: `${match.x}%`,
          top: `${match.y}%`,
          width: `${match.w}%`,
          height: `${match.h}%`
        };
      default:
        return {
          left: `${match.x}%`,
          top: rect ? `calc(${match.y}%  - ${rect.height / 4}px)` : `${match.y}%`,
          animation: `1s ease 0.5s 1 clickPoint`,
          animationIterationCount: 'infinite'
        };
    }
  };

  squareClickPointSegmentStyle = (segment) => {
    return {
      animation: `1s ease .5s 1 ${segment}`,
      animationIterationCount: 'infinite'
    };
  };

  render() {
    const { guide, playVideo, step, targetHidden } = this.state;
    const { video, points, startTime, guidedMode } = this.props;
    const match = points && step < points.length ? points[step] : null;
    const matchStyle = this.getMatchStyle(match, guide);
    const videoUrl = startTime ? `${video}#t=${startTime}` : video;

    return (
      <div className="portal" onClick={this.onDebugClick}>
        <div className="video-player-wrapper">
          <ReactPlayer
            ref={this.videoPlayerRef}
            url={videoUrl}
            width="100%"
            height="100%"
            className="video-player"
            progressInterval={PROGRESS_INTERVAL}
            playing={playVideo}
            onProgress={this.getCurrentProgress}
            muted
          />
        </div>
        {!playVideo && !!match && (match.x === 0 || !!match.x) && (match.y === 0 || !!match.y) && (
          <>
            <div className="click-target" style={matchStyle} onClick={this.nextStep} ref={this.props.targetClickRef} />
          </>
        )}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <VideoContainer targetClickRef={ref} {...props} />);

VideoContainer.defaultProps = {
  targetHidden: false
};
