import React, { useEffect, useState, useRef } from 'react';
import VideoContainer from '../../components/Clickthrough/VideoContainer';
import Video from '../../assets/videos/Oracle-RAG-Worker-Demo.mp4';
import data from './data';
import './VideoPage.scss';

export const VideoPage = ({ handleOpenClickThrough }) => {
  const [triggerPlayVideo, setTriggerPlayVideo] = useState(false);
  const [videoStepIndex, setVideoStepIndex] = useState(0);
  const [guidedMode, setGuidedMode] = useState(false);
  const [movedToPreviousStep, setMovedToPreviousStep] = useState(false);
  const targetClickRef = useRef(null);
  const [seekTimeStamp, setSeekTimeStamp] = useState(null);

  const handleTimeStampChange = () => {
    setSeekTimeStamp(null);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Toggle guided mode on 'n' key press
      if (e.key === 'n') {
        setGuidedMode(!guidedMode);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [guidedMode]);

  return (
    <>
      <div className="portal-wrap">
        {!!Video && (
          <VideoContainer
            video={Video}
            points={data.points}
            openUrl={handleOpenClickThrough}
            triggerPlayVideo={triggerPlayVideo}
            setTriggerPlayVideo={setTriggerPlayVideo}
            setVideoStepIndex={setVideoStepIndex}
            seekTimeStamp={seekTimeStamp}
            onTimeStampChange={handleTimeStampChange}
            videoStepIndex={videoStepIndex}
            targetClickRef={targetClickRef}
            guidedMode={guidedMode}
            movedToPreviousStep={movedToPreviousStep}
            setMovedToPreviousStep={setMovedToPreviousStep}
          />
        )}
      </div>
    </>
  );
};

export default VideoPage;
