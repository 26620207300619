const data = {
  points: [
    {
      id: '1',
      ts: 12.5,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      title: 'Lorem',
      details: 'Lorem',
      clickPointType: 'square',
      placement: 'top'
    },
    {
      id: '2',
      ts: 24.0,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      title: 'Lorem',
      details: 'Lorem',
      clickPointType: 'square',
      placement: 'top'
    },
    {
      id: '3',
      ts: 35.2,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      title: 'Lorem',
      details: 'Lorem',
      clickPointType: 'square',
      placement: 'top'
    },
    {
      id: '4',
      ts: 41.0,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      title: 'Lorem',
      details: 'Lorem',
      clickPointType: 'square',
      placement: 'top'
    },
    {
      id: '5',
      ts: 54.0,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      title: 'Lorem',
      details: 'Lorem',
      clickPointType: 'square',
      placement: 'top'
    },
    {
      id: '6',
      ts: 67.5,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      title: 'Lorem',
      details: 'Lorem',
      clickPointType: 'square',
      placement: 'top'
    },
    {
      id: '7',
      ts: 75.5,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      title: 'Lorem',
      details: 'Lorem',
      clickPointType: 'square',
      placement: 'top'
    },
    {
      id: '8',
      ts: 94.2,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      title: 'Lorem',
      details: 'Lorem',
      clickPointType: 'square',
      placement: 'top'
    },
    {
      id: '9',
      ts: 105.0,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      title: 'Lorem',
      details: 'Lorem',
      clickPointType: 'square',
      placement: 'top'
    },
    {
      id: '10',
      ts: 116.9,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      title: 'Lorem',
      details: 'Lorem',
      clickPointType: 'square',
      placement: 'top'
    },
    {
      id: '11',
      ts: 121.5,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      title: 'Lorem',
      details: 'Lorem',
      clickPointType: 'square',
      placement: 'top'
    },
    {
      id: '12',
      ts: 133,
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      title: 'Lorem',
      details: 'Lorem',
      clickPointType: 'square',
      placement: 'top'
    }
  ]
};

export default data;
